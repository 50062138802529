/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatTableDataSource } from '@angular/material/table';
import * as firebase from 'firebase';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { from } from 'rxjs';
import { take } from 'rxjs/operators';
import { Client } from '../models/client.model';
import { Patient } from '../models/patient.model';
import { TimeZone, User } from '../models/user.model';
import { SurveyConf } from '../shared/components/client-detail/client-demographics/survey-conf/survey-conf.model';
import { DateTimeWelbyCustomPipe } from '../shared/pipes/date-time-custom.pipe';
import { OrderVO } from './device-ordering/value-objects/order.vo';
import { FirestoreService } from './firestore.service';
import { TrackingData, TrackingProvider } from './models/trackingInfo.model';

interface ElasticSearchTimeStamp {
  _seconds: number;
  _nanoseconds: number;
}

type GeneralDate = firebase.firestore.Timestamp | Date | ElasticSearchTimeStamp;
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private datePipe: DateTimeWelbyCustomPipe, private fs: FirestoreService, private firestore: AngularFirestore) {}

  /**
   * Get American phone number format
   */
  getPhoneNumberFormated(phone: string): string {
    const numbers = [];
    numbers.push(phone.substring(0, 3));
    if (phone.substring(3, 6) !== '') {
      numbers.push(phone.substring(3, 6));
    }
    if (phone.substring(6, 10) !== '') {
      numbers.push(phone.substring(6, 10));
    }
    return numbers.join('-').substring(0, 12);
  }

  getTrackingInfo(order: OrderVO | any, trackingProviders: TrackingProvider): TrackingData {
    const carrier = order?.orderStatus?.order?.carrier?.toLowerCase() ?? 'none';
    const lineItems = order?.orderStatus?.order?.lines ?? 'none';
    const tracking_number = order?.orderStatus?.order?.tracking_number ?? 'none';

    const trackingUrl =
      `${trackingProviders[carrier]?.['url']}` !== 'none' ? `${trackingProviders[carrier]?.['url']}${tracking_number}` : order?.orderStatus?.tracking_link ?? 'none';
    const trackingIcon = trackingProviders[carrier]['icon'] !== 'none.png' ? trackingProviders[carrier]['icon'] : 'delivery.png';
    const trackingInfo = {
      carrier,
      lineItems,
      tracking_number,
      trackingUrl,
      trackingIcon,
    };
    return trackingInfo;
  }

  async getTrackingProviders(): Promise<any> {
    return await this.fs.doc$(`welby_configuration/tracking_providers`).pipe(take(1)).toPromise();
  }

  /**
   * Base64 from file
   */
  getBase64FromFile(file: Blob): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const extractaFile = reader && reader.result ? (reader.result as string).split(',')[1] : '';
        resolve(extractaFile);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  /**
   * Get label and color for tier
   *
   * @param tier number
   * @returns
   */
  getTierLabel(tier: number): { label: string; color: string } {
    switch (tier) {
      case 3:
        return { label: 'High', color: 'warn' };
      case 2:
        return { label: 'Medium', color: 'accent' };
      case 1:
        return { label: 'Low', color: 'primary' };
      default:
        return { label: 'Unknown', color: 'default' };
    }
  }

  /**
   * Get date from firebase timestamp
   */
  formatDate(timestamp: any, format = 'lll') {
    try {
      let date;
      if (typeof timestamp === 'number') {
        date = new Date(timestamp);
      } else if (timestamp.toDate) {
        date = timestamp.toDate();
      } else if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      }
      const m = moment(date);
      return m.format(format);
    } catch (error) {
      console.error('Error formatting date', error);
      return 'N/A';
    }
  }

  getTodayDate() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return now;
  }

  getTodayEODDate(timezone?: TimeZone) {
    const now = timezone ? moment().tz(timezone.nameValue) : moment();
    const todayEODDate = now.endOf('day').toDate();
    return todayEODDate;
  }

  getLastWeekDate(timezone?: TimeZone) {
    const now = timezone ? moment().tz(timezone.nameValue) : moment();
    const lastWeekDate = now.subtract(7, 'days').startOf('day').toDate();
    return lastWeekDate;
  }

  getLastMonthDate(timezone?: TimeZone) {
    const now = timezone ? moment().tz(timezone.nameValue) : moment();
    const lastMonthDate = now.subtract(1, 'month').startOf('day').toDate();
    return lastMonthDate;
  }

  getLast90DaysDate(timezone?: TimeZone) {
    const now = timezone ? moment().tz(timezone.nameValue) : moment();
    const lastMonthDate = now.subtract(3, 'month').startOf('day').toDate();
    return lastMonthDate;
  }

  getLast180DaysDate(timezone?: TimeZone) {
    const now = timezone ? moment().tz(timezone.nameValue) : moment();
    const lastMonthDate = now.subtract(6, 'month').startOf('day').toDate();
    return lastMonthDate;
  }

  getThreeDaysAfterToday() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 3);
  }

  getA1CfromeAG(mgdl: number) {
    return ((mgdl + 46.7) / 28.7).toFixed(3);
  }

  sortUsersByAlphabeticOrder(userA: User, userB: User) {
    return userA.firstName > userB.firstName ? 1 : userB.firstName > userA.firstName ? -1 : 0;
  }

  sortUsersByAlphabeticLastNameOrder(userA: User, userB: User) {
    return userA.lastName > userB.lastName ? 1 : userB.lastName > userA.lastName ? -1 : 0;
  }

  sortClientsByAlphabeticOrder(clientA: Client, clientB: Client) {
    return clientA.client_name > clientB.client_name ? 1 : clientB.client_name > clientA.client_name ? -1 : 0;
  }

  getQueryStringFromParams(params): string {
    const query =
      Object.keys(params).length > 1
        ? Object.keys(params).reduce((prev, current, index) => {
            let result: string;
            if (index === 1) {
              result = `?${prev}=${params[prev]}&${current}=${params[current]}`;
            } else {
              result = `${prev}&${current}=${params[current]}`;
            }
            return result;
          })
        : `?${Object.keys(params)[0]}=${params[Object.keys(params)[0]]}`;
    return query;
  }

  isGreaterThanYear(lastDateForm): boolean {
    const date = lastDateForm;
    if (!date) {
      return true;
    } else {
      const dateMillis = date.seconds * 1000;
      const dateMoment = moment(dateMillis);
      const currentDate = moment();
      const diffInYears = currentDate.diff(dateMoment, 'years');
      return diffInYears >= 1;
    }
  }

  getChunksWhenArrayLengthIsGreaterThanMaxValue(array: string[]) {
    const MAX_IN_VALUES = 8;
    const chunks = [];

    for (let i = 0; i < array.length; i += MAX_IN_VALUES) {
      chunks.push(array.slice(i, i + MAX_IN_VALUES));
    }
    return chunks;
  }

  getChunksObsWhenArrayLengthIsGreaterThanMaxValue(array: string[]) {
    return from(this.getChunksWhenArrayLengthIsGreaterThanMaxValue(array));
  }

  /**
   * Check if survey is enabled. Only can be done once per month
   */
  async isSurveyEnabled(lastSurveyDate: any, clientId: string): Promise<boolean> {
    if (!lastSurveyDate) {
      return true;
    }
    let maxDiffDays: number;
    const clientSurveyConf = await this.fs.doc$(`client_survey_conf/${clientId}`).pipe(take(1)).toPromise();
    if (clientSurveyConf) {
      maxDiffDays = clientSurveyConf['limit_range_days'];
    } else {
      const welbySurveyConf = await this.fs.doc$(`welby_configuration/default_survey_conf`).pipe(take(1)).toPromise();
      maxDiffDays = welbySurveyConf['limit_range_days'];
    }
    const currentDate = new Date();
    const lastSurveyDateObj = lastSurveyDate.toDate();
    const diffTime = Math.abs(currentDate.getTime() - lastSurveyDateObj.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays >= maxDiffDays;
  }

  /**
   * Return patient with format dates from ES
   *
   * @param patient patient
   * @param patient patient
   * @returns Patient
   */
  addDefaultTZ(patient: Patient): Patient {
    if (patient?.timezone?.abbr) {
      return patient;
    }
    patient.timezone = {
      abbr: 'PDT',
      group: 'US',
      name: 'US/Pacific (-07:00)',
      nameValue: 'US/Pacific',
      timeValue: '-07:00',
    };
    return patient;
  }

  mapDates(patient: Patient): Patient {
    if (patient.dates?.last_measurement_date) {
      patient.last_measurement_date = this.datePipe.transform(patient.dates?.last_measurement_date?._seconds * 1000, patient.timezone?.nameValue) ?? 'N/A';
    } else {
      patient.last_measurement_date = 'N/A';
    }
    if (patient.dates?.last_assessment) {
      patient.last_assessment = this.datePipe.transform(patient.dates?.last_assessment?._seconds * 1000, patient.timezone?.nameValue) ?? 'N/A';
    } else {
      patient.last_assessment = 'N/A';
    }
    if (patient.dates?.start_date) {
      patient.dates.start_date = this.datePipe.transform(patient.dates?.start_date?._seconds * 1000, patient.timezone?.nameValue) ?? 'N/A';
    }
    return patient;
  }

  /**
   * Normalizes the sort date accessors for the patient list.
   *
   * @param patientList - The MatTableDataSource of patients.
   * @returns void
   */
  normalizeSortDateAccessors(patientList: MatTableDataSource<Patient>): void {
    const dateformat = 'MM/DD/YY h:mm A z';
    patientList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'last_measurement_date':
          return item.last_measurement_date && item.last_measurement_date !== 'N/A'
            ? momentTz.tz(item.last_measurement_date, dateformat, item.timezone.nameValue)?.toDate()
            : new Date(2000, 0, 1);
        case 'last_assessment':
          return item.last_assessment && item.last_assessment !== 'N/A' ? momentTz.tz(item.last_assessment, dateformat, item.timezone.nameValue)?.toDate() : new Date(2000, 0, 1);
        default:
          return item[property];
      }
    };
  }

  async showpdf(response) {
    const file = this.createPdfFile(response);
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  createPdfFile(response) {
    return new Blob([response], { type: 'application/pdf' });
  }

  normalizedTwilioLines(user: User) {
    return user.twilio_line ? (Array.isArray(user.twilio_line) ? user.twilio_line : [user.twilio_line]) : [];
  }

  addLeadingZero(n: number) {
    return n < 10 ? '0' + n : '' + n;
  }

  async getSurveyConf(clientId: string): Promise<any> {
    if (!clientId) {
      return { limit_range_days: 30, surveys: { general_survey: '', interactions_survey: '' } };
    }
    const clientSurveyConf = await this.fs.doc$<SurveyConf>(`client_survey_conf/${clientId}`).pipe(take(1)).toPromise();
    if (clientSurveyConf) {
      return { ...clientSurveyConf, isClientConf: true };
    } else {
      const defaultConf = await this.fs.doc$<SurveyConf>(`welby_configuration/default_survey_conf`).pipe(take(1)).toPromise();
      return { ...defaultConf, isClientConf: false };
    }
  }

  async saveSurveyConf(clientId: string, surveyConf: SurveyConf): Promise<void> {
    await this.fs.set(`client_survey_conf/${clientId}`, surveyConf);
  }

  patientNameCapitalize(name: string, truncatedSecondWord: boolean) {
    return name
      .split(' ')
      .filter((names) => names !== '')
      .map((n, index) => {
        if (index === 0) {
          return n.charAt(0).toUpperCase() + n.slice(1).toLowerCase();
        } else if (truncatedSecondWord) {
          return this.validateSpecialCharacters(n).charAt(0).toUpperCase() + '.';
        } else {
          return this.validateSpecialCharacters(n).charAt(0).toUpperCase() + n.slice(1).toLowerCase();
        }
      })
      .join(' ');
  }
  validateSpecialCharacters(name: string): string {
    return name.startsWith("'") || name.startsWith('(') || name.startsWith('*') ? name.slice(1) : name;
  }
}
