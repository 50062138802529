import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeAll, take, tap } from 'rxjs/operators';
import { Device } from 'src/app/models/patient.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';
import { NewAuthService } from '../auth/new-auth-service.service';
import { InitialDataModel } from '../models/initial-data-model.service';
import { UtilsService } from '../utils.service';
import { DeviceVO, OrderRequestApprovedState, OrderRequestVO, OrderVO } from './value-objects/order.vo';

export const DEVICE_ORDERS_TABLE = 'hardware_orders';
export const DEVICE_ORDERS_REQUEST_TABLE = 'hardware_order_requests';

@Injectable({
  providedIn: 'root',
})
export class DeviceOrderingService {
  public manufacturerNames$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public manufacturerDevices$: BehaviorSubject<DeviceVO[]> = new BehaviorSubject<DeviceVO[]>([]);

  private devices: DeviceVO[];

  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private fsSercie: FirestoreService,
    private auth: NewAuthService,
    private initialModelService: InitialDataModel,
    private utilsService: UtilsService
  ) {}

  orderDevice(order: OrderVO, orderId?: string): Observable<any> {
    order.orderId = orderId ? orderId : this.db.createId();
    const endpoint = environment.welbyEndpoint + `/api/v1/device/order/create/${order.manufacturerId.toLowerCase()}`;
    return this.http.post<any>(endpoint, order);
  }

  requestDevice(order: OrderVO): Promise<any> {
    const hardwareOrderRequest: OrderRequestVO = {
      request_body: order,
      id: this.db.createId(),
      requester_id: this.auth.user.user_id,
      client_responsible_id: order.clientId,
      approved: OrderRequestApprovedState.PENDING,
      comments: '',
    };
    return this.fsSercie.set(`${DEVICE_ORDERS_REQUEST_TABLE}/${hardwareOrderRequest.id}`, hardwareOrderRequest);
  }

  updateOrderRequest(orderRequest: OrderRequestVO): Promise<any> {
    return this.fsSercie.update(`${DEVICE_ORDERS_REQUEST_TABLE}/${orderRequest.id}`, {
      approved: orderRequest.approved,
      comments: orderRequest.comments,
    });
  }

  /**
   * Update order. Only client can be updated.
   */
  updateOrderDevice(orderVO: OrderVO): Promise<any> {
    return this.fsSercie.update(`${DEVICE_ORDERS_TABLE}/${orderVO.orderId}`, {
      clientId: orderVO.clientId,
      orderStatus: orderVO.orderStatus,
    });
  }

  getDeviceOrdersByUserId(client_id: string): Observable<any[]> {
    const availableClients = this.initialModelService.clientAccounts;

    if (this.auth.user.roles.isAdmin) {
      return this.fsSercie.col$(DEVICE_ORDERS_TABLE);
    } else if (availableClients.length <= 8) {
      return this.fsSercie.col$(DEVICE_ORDERS_TABLE, (ref) => ref.where('clientId', 'in', availableClients));
    } else {
      const chunks$ = this.utilsService.getChunksObsWhenArrayLengthIsGreaterThanMaxValue(availableClients);

      const query$ = chunks$.pipe(
        map((chunk) => this.fsSercie.col$(DEVICE_ORDERS_TABLE, (ref) => ref.where('clientId', 'in', chunk))),
        mergeAll()
      );
      return query$;
    }
  }

  getDeviceOrdersByPatientId(patientId: string): Observable<OrderVO[]> {
    return this.db
      .collection<OrderVO>(DEVICE_ORDERS_TABLE, (ref) => ref.where('patient.id', '==', patientId))
      .valueChanges()
      .pipe(map((orders) => orders as OrderVO[]));
  }

  getOrderStatus(manufacturerId: string, orderId: string): Observable<any> {
    const endpoint = environment.welbyEndpoint + `/api/v1/device/order/status/${manufacturerId.toLowerCase()}/${orderId}`;
    return this.http.get<any>(endpoint);
  }

  getOrderById(orderId): Observable<any> {
    return this.fsSercie.doc$(`${DEVICE_ORDERS_TABLE}/${orderId}`);
  }

  getAllDevices(): Observable<DeviceVO[]> {
    if (this.devices) {
      return of(this.devices);
    } else {
      return this.db
        .collection('devices')
        .valueChanges()
        .pipe(
          take(1),
          map((devices) => devices as DeviceVO[]),
          tap((devices) => (this.devices = devices)),
          tap((devices) => this.manufacturerNames$.next([...new Set(devices.map((device) => device.mfg_display))].sort()))
        );
    }
  }

  getDevicesByManufacturer(manufacturer: string): Observable<DeviceVO[]> {
    return this.getAllDevices().pipe(
      map((devices) => devices.filter((device) => device.mfg_display === manufacturer)),
      tap((devices) => this.manufacturerDevices$.next(devices))
    );
  }

  loadDeviceToUserAccount(isNew: boolean, order: OrderVO, imei?: string) {
    const newDevice: Device = {
      ordered: false,
      delivered: false,
      active: false,
      auth_token: 'N/A',
      device_manufacturer: order.device.manufacturer,
      device_model: order.device.type,
      device_id: !!imei ? imei : 'N/A',
      imageURL: order.device.imageURL,
      last_update: new Date(),
      refresh_token: 'N/A',
      user_id: 0,
      order_notes: order.orderNotes,
    };

    const deviceMap = `${order.device.manufacturer}_${order.device.type}`;
    const ref = this.db.collection('users').doc(order.patient.id).collection('my_devices').doc(deviceMap);

    // just sending a generic email about the load.
    const requestMessage = {
      to: ['seth@getwelby.com'],
      message: {
        subject: 'New Device Request',
        text: `New request for ${order.patient.lastName}. Request for a ${order.device.manufacturer} ${order.device.type} to be ordered`,
        html: `New request for ${order.patient.lastName}. Request for a ${order.device.manufacturer} ${order.device.type} to be ordered`,
      },
    };

    if (isNew) {
      this.db.collection('device_requests').add(requestMessage);
      ref.set(newDevice);
    } else {
      ref.set(newDevice, { merge: true });
    }

    if (order.device.imei !== 'N/A') {
      return this.mapDeviceToUser(order.patient.id, newDevice, order.clientId);
    } else {
      return;
    }
  }

  async mapDeviceToUser(uid: string, device: Device, client_id: string) {
    const patientSnapshot = await this.db.collection('users').doc(uid).get().pipe(take(1)).toPromise();
    const patient = patientSnapshot.data();
    // maps the device to the user in the 'device_user_mapping' table
    const mapID = `${uid}_${device.device_id}`;
    this.db
      .collection('device_user_mapping')
      .doc(mapID)
      .set({
        client_id,
        device_id: device.device_id,
        device_mfg: device.device_manufacturer,
        device_ref_id: device.device_manufacturer + '_' + device.device_model,
        user_id: uid,
        user_timezone: patient.timezone,
        lastUpdate: new Date(),
      });
  }

  mappingDeviceToPatient(orderRequest: OrderRequestVO) {
    const endpoint = environment.welbyEndpoint + `/api/v1/device/mapping/map/from-order-request/${orderRequest.request_body.device.imei}`;
    return this.http.post<any>(endpoint, orderRequest);
  }
}
